import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,

} from "react-router-dom";

import { App } from "./components/App";


export default function Router(props) {
const basePath = "";

const router = createBrowserRouter([
  {
    path: `${basePath}`,
    element: <App/>,
  },

]);

return (
    <React.StrictMode>
    <RouterProvider router={router} />
    </React.StrictMode>
)
}